import React from 'react';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import DefaultInput, {HalfWrapper, SelectOption} from '../utils/default_input';
import {postModel, getPhoneText, setInputCursor} from '../utils/functions';
import WarningMessage from '../utils/warning_message';
import './subscribe.scss';
import * as routes from '../constants';
import {DEFAULT_ERROR_MESSAGE} from '../constants';


class SubscribePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: "",
        email: "",
        phone: "",
        objective: "",
        desired_services: []
      },
      highlights: [],
      showMessage: false,
      message: "",
      sending: false,
      messageBackground: null,
      messageColor: null,
      screenWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'G-FXDWBKYNPM', {'page_path': routes.SUBSCRIBE_PATH});
    }

    let params = new URLSearchParams(window.location.search);
    const selectedService = params.get('servico');

    if(selectedService) {
      const data = {...this.state.data, desired_services: [selectedService]};

      this.setState({data});
    }

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);

    // this.scrollListener = () => this.updateScroll();
    // window.addEventListener("scroll", this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
    // window.removeEventListener("scroll", this.scrollListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name.startsWith('desired_services:')) {
      const selection = name.split(':');
      const service = selection[1];

      name = 'desired_services';

      let valueCopy = [...this.state.data.desired_services];

      if(value) {
        if(!valueCopy.includes(service)) {
          valueCopy.push(service);
        }
      }
      else {
        const serviceIndex = valueCopy.indexOf(service);

        if(serviceIndex >= 0) {
          valueCopy.splice(serviceIndex, 1);
        }
      }

      value = valueCopy;
    }
    else if(value && name === 'phone') {
      const initialPosition = target.selectionStart;
      const initialSize = value.length;

      value = value.replace(/\D/g,"")

      target.value = getPhoneText(value);

      let newPosition = (target.value.length - initialSize) + initialPosition;

      if(initialPosition < initialSize) {
        newPosition = initialPosition;
      }

      if(value.length > 2 &&
          (newPosition === 4)) {
        newPosition = 6;
      }

      setInputCursor(target, newPosition);
    }

    const data = {...this.state.data, [name]: value};

    this.setState({
      data
    });
  }

  async sendRequest() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'Submitting subscription', {
        'event_category' : 'Form submit',
        'event_label' : 'Presale subscription'
      });
    }

    const highlights = [];
    const messages = [];

    if(this.state.data.name.length <= 0) {
      highlights.push('name');
      messages.push('Nome não preencido');
    }

    if(this.state.data.email.length <= 0) {
      highlights.push('email');
      messages.push('E-mail não preencido');
    }
    else if(this.state.data.email.match(/.+@.+\..+/) === null) {
      highlights.push('email');
      messages.push('Formato de e-mail inválido');
    }

    if(this.state.data.phone.length <= 0) {
      highlights.push('phone');
      messages.push('Telefone de contato não preencido');
    }
    else if(this.state.data.phone.length < 10) {
      highlights.push('phone');
      messages.push('DDD de telefone para contato não preenchido');
    }

    if(this.state.data.desired_services.length <= 0) {
      highlights.push('desired_services');
      messages.push('Selecione um ou mais serviços desejados');
    }

    if(!this.state.data.objective.length || this.state.data.objective.length <= 0) {
      highlights.push('objective');
      messages.push('Selecione um objetivo principal');
    }

    if(highlights.length > 0) {
      this.setState({
        highlights,
        showMessage: true,
        message: `${messages.join('. ')}.`,
        messageBackground: null,
        messageColor: null
      });

      window.scrollTo(0, 0);
    }
    else {
      this.setState({
        highlights: [],
        sending: true,
        message: "",
        showMessage: false
      });

      const data = {...this.state.data};

      try {
        if(await postModel(routes.PRESALE_LEAD_POST_API, data)) {
          this.setState({
            sending: false,
            showMessage: true,
            message: "Agradecemos pela confiança! Fique atento que em breve enviaremos mais informações sobre esta oferta imperdível.",
            messageBackground: "#dbf3c1",
            messageColor: "#456d19",
            data: {
              name: "",
              email: "",
              phone: "",
              objective: "",
              desired_services: [],
            }
          });

          if (process.env.NODE_ENV === 'production') {
            window.gtag('event', 'Subscription confirmed', {
              'event_category' : 'Submit confirmation',
              'event_label' : 'Presale subscription'
            });
          }

          window.scrollTo(0, 0);
        }
      }
      catch(errors) {
        let highlights = [];
        let message = DEFAULT_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 103:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'email':
        //               message = 'Email já está cadastrado. Caso possua qualquer dúvida adicional, por favor, entre em contato conosco para que possamos lhe ajudar.';
        //               highlights.push('email');
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          highlights,
          sending: false,
          showMessage: true,
          message,
          messageBackground: null,
          messageColor: null
        });

        window.scrollTo(0, 0);

        return;
      }
    }
  }

  getObjectiveOptions() {
    const objectives = [
      'Emagrecimento',
      'Ganho de massa muscular (hipertrofia)',
      'Performance',
      'Qualidade de vida',
    ];

    return [
      SelectOption('', 'Selecione um objectivo'),
      ...objectives.map((objective) => SelectOption(objective, objective))
    ];
  }

  getVideoSize() {
    if(this.state.screenWidth <= 380) {
      return ['302', '170'];
    }
    else if(this.state.screenWidth <= 470) {
      return ['356', '200'];
    }
    else if(this.state.screenWidth <= 600) {
      return ['427', '240'];
    }
    else if(this.state.screenWidth <= 870) {
      return ['462', '260'];
    }
    else if(this.state.screenWidth <= 1080) {
      return ['444', '250'];
    }

    return ['533', '300'];
  }

  render() {
    const videoSize = this.getVideoSize();

    return (
      <React.Fragment>

        <MainHeader
          location={this.props.location}
          cleanBackground={true}
        />

        <div className="subscribe__wrapper">

          <main className="subscribe">

            <h2 className="subscribe__title">

              Pré venda de <span className="subscribe__red-text">inauguração</span>

            </h2>

            <div className="subscribe__description-wrapper">

              <iframe
                className="subscribe__video"
                title="Vídeo do YouTube falando sobre a inauguração"
                width={videoSize[0]}
                height={videoSize[1]}
                src="https://www.youtube.com/embed/kx7fjy1pKIg"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              <p className="subscribe__description">

                Nossa pré venda se iniciará <span className="subscribe__description__highlight">em breve</span> e vamos
                lançar <span className="subscribe__description__highlight">ofertas imperdíveis</span> aos primeiros interessados.
                Preencha o formulário abaixo e seja um dos primeiros a receber todas as informações
                de <span className="subscribe__description__highlight">preços, planos e horários</span>.

              </p>

            </div>

            <div className="subscribe__form-container">

              <div className="subscribe__warning-container">

                <WarningMessage
                  message={this.state.message}
                  onClose={() => this.setState({highlights: [], showMessage: false})}
                  visible={this.state.showMessage}
                  background={this.state.messageBackground}
                  color={this.state.messageColor}
                />

              </div>

              <HalfWrapper>

                <DefaultInput
                  className="subscribe__input"
                  name="name"
                  type="text"
                  label="Nome:"
                  // isHorizontal={this.state.screenWidth > 470}
                  isHighlighted={this.state.highlights.includes('name')}
                  placeholder="Nome"
                  maxLength="128"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.data.name}
                  autoComplete="off"
                  disabled={this.state.sending}
                />

                <DefaultInput
                  className="subscribe__input"
                  name="email"
                  type="email"
                  label="E-mail:"
                  // isHorizontal={this.state.screenWidth > 470}
                  isHighlighted={this.state.highlights.includes('email')}
                  placeholder="E-mail"
                  maxLength="128"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.data.email}
                  autoComplete="off"
                  disabled={this.state.sending}
                />

              </HalfWrapper>

              <HalfWrapper>

                <DefaultInput
                  className="subscribe__input"
                  name="phone"
                  isHighlighted={this.state.highlights.includes('phone')}
                  type="tel"
                  label="Telefone:"
                  // isHorizontal={this.state.screenWidth > 470}
                  placeholder="DDD + número"
                  maxLength="16"
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={getPhoneText(this.state.data.phone)}
                  autoComplete="off"
                  disabled={this.state.sending}
                />

                <DefaultInput
                  className="subscribe__input"
                  name="objective"
                  isHighlighted={this.state.highlights.includes('objective')}
                  type="select"
                  label="Objetivo principal:"
                  // isHorizontal={this.state.screenWidth > 470}
                  handleInputChange={(event) => this.handleInputChange(event)}
                  value={this.state.data.objective || ''}
                  options={this.getObjectiveOptions()}
                  autoComplete="off"
                  disabled={this.state.sending}
                />

              </HalfWrapper>

              <section className="subscribe__service-input">

                <h4 className="subscribe__service-input__label">Selecione os serviços de seu interesse:</h4>

                <ul className="subscribe__service-input__options-list">

                  <li className="subscribe__service-input__option">

                    <DefaultInput
                      className={`subscribe__service-input__checkbox${this.state.data.desired_services.includes('funcional') ? '--checked' : ''}`}
                      name="desired_services:funcional"
                      isHighlighted={this.state.highlights.includes('desired_services')}
                      label={this.state.screenWidth > 470 ? 'Treinamento funcional' : <span>Treinamento<br/>funcional</span>}
                      isHorizontal={true}
                      invertLabel={true}
                      type="checkbox"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.data.desired_services.includes('funcional')}
                      disabled={this.state.sending}
                    />

                  </li>

                  <li className="subscribe__service-input__option">

                    <DefaultInput
                      className={`subscribe__service-input__checkbox${this.state.data.desired_services.includes('pilates') ? '--checked' : ''}`}
                      name="desired_services:pilates"
                      isHighlighted={this.state.highlights.includes('desired_services')}
                      label="Pilates"
                      isHorizontal={true}
                      invertLabel={true}
                      type="checkbox"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.data.desired_services.includes('pilates')}
                      disabled={this.state.sending}
                    />

                  </li>

                  <li className="subscribe__service-input__option">

                    <DefaultInput
                      className={`subscribe__service-input__checkbox${this.state.data.desired_services.includes('gym') ? '--checked' : ''}`}
                      name="desired_services:gym"
                      isHighlighted={this.state.highlights.includes('desired_services')}
                      label="Musculação"
                      isHorizontal={true}
                      invertLabel={true}
                      type="checkbox"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.data.desired_services.includes('gym')}
                      disabled={this.state.sending}
                    />

                  </li>

                  <li className="subscribe__service-input__option">

                    <DefaultInput
                      className={`subscribe__service-input__checkbox${this.state.data.desired_services.includes('bike') ? '--checked' : ''}`}
                      name="desired_services:bike"
                      isHighlighted={this.state.highlights.includes('desired_services')}
                      label="Bike"
                      isHorizontal={true}
                      invertLabel={true}
                      type="checkbox"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.data.desired_services.includes('bike')}
                      disabled={this.state.sending}
                    />

                  </li>

                  <li className="subscribe__service-input__option">

                    <DefaultInput
                      className={`subscribe__service-input__checkbox${this.state.data.desired_services.includes('core') ? '--checked' : ''}`}
                      name="desired_services:core"
                      isHighlighted={this.state.highlights.includes('desired_services')}
                      label="Core"
                      isHorizontal={true}
                      invertLabel={true}
                      type="checkbox"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.data.desired_services.includes('core')}
                      disabled={this.state.sending}
                    />

                  </li>

                  <li className="subscribe__service-input__option">

                    <DefaultInput
                      className={`subscribe__service-input__checkbox${this.state.data.desired_services.includes('cardio') ? '--checked' : ''}`}
                      name="desired_services:cardio"
                      isHighlighted={this.state.highlights.includes('desired_services')}
                      label="Cardio"
                      isHorizontal={true}
                      invertLabel={true}
                      type="checkbox"
                      handleInputChange={(event) => this.handleInputChange(event)}
                      value={this.state.data.desired_services.includes('cardio')}
                      disabled={this.state.sending}
                    />

                  </li>

                </ul>

              </section>

              <button
                className={`subscribe__send-form-button${this.state.sending ? '--active' : ''}`}
                onClick={() => this.sendRequest()}
                disabled={this.state.sending}
              >
                {this.state.sending ? 'Enviando...' : 'Enviar'}
              </button>

            </div>

          </main>

          <MainFooter />

        </div>

      </React.Fragment>
    );
  }
}

export default SubscribePage;
