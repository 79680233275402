import React from 'react';
import './main_footer.scss'

class MainFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <footer className="main-footer">

        <div className="main-footer__content">

          <div className="main-footer__wrapper">

            <div className="main-footer__column-group--1">

              <p className="main-footer__description">

                <span className="main-footer__description__highlighted">Inauguração</span> do espaço no dia <span className="main-footer__description__highlighted">11/01/2021</span> Aproveite esta oportunidade!

              </p>

            </div>

            <div className="main-footer__column-group--2">

              <address className="main-footer__address">

                <p className="main-footer__address__item">

                  <i className="fas fa-home main-footer__address__item__icon"></i>
                  <span className="main-footer__address__item__text">Av. Comendador Alfredo Maffei, 3927, Jardim Nossa Sra. Aparecida, São Carlos, SP</span>

                </p>

                <p className="main-footer__address__item">

                  <i className="fab fa-whatsapp main-footer__address__item__icon"></i>
                  <a
                    href="https://api.whatsapp.com/send?phone=5516992883013&text=Quero%20reservar%20o%20plano%20da%20pré-venda!"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="main-footer__address__item__text--clickable"
                    onClick={() => {
                      if (process.env.NODE_ENV === 'production') {
                        window.gtag('event', 'Click', {
                          'event_category' : 'Address',
                          'event_label' : 'Phone number'
                        });
                      }
                    }}
                  >

                    (16) 99288-3013

                  </a>

                </p>

              </address>

            </div>

          </div>

        </div>

        <p className="main-footer__current-website">

          Conheça nossa unidade atual no site
          <a
            href="https://fydcentrodetreinamento.com.br"
            className="main-footer__current-website__link"
            rel="noreferrer noopener"
            target="_blank"
          >
            https://fydcentrodetreinamento.com.br
          </a>

        </p>

        <p className="main-footer__copyright">

          Copyrights © {(new Date()).getFullYear()} Todos os direitos reservados

        </p>

      </footer>
    );
  }
}

export default MainFooter
