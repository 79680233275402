import React from 'react';
import { Link } from 'react-router-dom';
import './main_header.scss'
import * as routes from '../constants';
import logo from '../images/logo_fyd_club.png';

class MainHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      scrollPosition: window.scrollY,
      menuVisible: false
    };
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    this.scrollListener = () => this.updateScroll();

    window.addEventListener("resize", this.resizeListener);
    window.addEventListener("scroll", this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
    window.removeEventListener("scroll", this.scrollListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  updateScroll() {
    this.setState({
      scrollPosition: window.scrollY
    });
  }

  getBasePath() {
    if(this.props.basePath) {
      return this.props.basePath;
    }

    return '';
  }

  getNavLink(text, path, checkPathCallback=null, external=false) {
    if(checkPathCallback !== null && checkPathCallback()) {
      return (
        <li className="main-header__navigation__link-wrapper--selected">
          <p className="main-header__navigation__link--selected">{text}</p>
        </li>
      );
    }

    return (
      <li
        className="main-header__navigation__link-wrapper"
        onClick={() => {
          if (process.env.NODE_ENV === 'production') {
            window.gtag('event', 'Click', {
              'event_category' : 'Navigation link',
              'event_label' : text
            });
          }
        }}
      >
        {external ?
          <a
            href={this.getBasePath() + path}
            className={`main-header__navigation__link${this.props.cleanBackground ? '--white' : ''}`}
            rel="noreferrer noopener"
            target="_blank"
          >
            {text}
          </a>:
          <a href={this.getBasePath() + path} className={`main-header__navigation__link${this.props.cleanBackground ? '--white' : ''}`}>{text}</a>
        }
      </li>
    );
  }

  getMainClass() {
    if(this.state.scrollPosition > 0) {
      return 'main-header--scrolled';
    }
    else if(this.props.visibleByDefault) {
      return 'main-header--visible';
    }

    return 'main-header';
  }

  render() {
    return (
      <header className={this.getMainClass()}>

        <div className="main-header__wrapper">

          <Link
            className={`main-header__logo${this.props.cleanBackground ? '--white' : ''}`}
            to={routes.MAIN_PATH}
            onClick={() => {
              if (process.env.NODE_ENV === 'production') {
                window.gtag('event', 'Click', {
                  'event_category' : 'Internal link',
                  'event_label' : 'Logo'
                });
              }
            }}
          >

            <img
              className="main-header__logo__image"
              src={logo}
              alt="FYD Club logo"
            />

            <div className="main-header__logo__text-wrapper">

              <p className="main-header__logo__text">FYD CLUB</p>
              <hr className="main-header__logo__hr" />
              <p className="main-header__logo__sub-text">CENTRO DE TREINAMENTO</p>

            </div>


          </Link>

          <div className="main-header__controls-container">

            <nav className="main-header__navigation">

              <ul className="main-header__navigation__links-container">

                {this.getNavLink('Serviços', routes.MAIN_PATH, () => {
                  return this.props.location.pathname === routes.MAIN_PATH || this.props.location.pathname.startsWith(routes.SERVICES_PATH);
                })}
                {this.getNavLink('Onde fica?', 'https://g.page/fyd-centrotreinamento?share', null, true)}
                {this.getNavLink('Saiba mais', routes.SUBSCRIBE_PATH, () => {
                  return this.props.location.pathname === routes.SUBSCRIBE_PATH;
                })}

              </ul>

            </nav>

          </div>

        </div>

      </header>
    );
  }
}

export default MainHeader
