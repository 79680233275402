import React from 'react';
import { Link } from 'react-router-dom';
import MainHeader from '../components/main_header';
import MainFooter from '../components/main_footer';
import './main_page.scss';
import * as routes from '../constants';

import funcional1_1920 from '../images/funcional_1_1920.jpg';
import funcional1_1920_2x from '../images/funcional_1_1920_2x.jpg';
import funcional1_1680 from '../images/funcional_1_1680.jpg';
import funcional1_1680_2x from '../images/funcional_1_1680_2x.jpg';
import funcional1_1440 from '../images/funcional_1_1440.jpg';
import funcional1_1440_2x from '../images/funcional_1_1440_2x.jpg';
import funcional1_1280 from '../images/funcional_1_1280.jpg';
import funcional1_1280_2x from '../images/funcional_1_1280_2x.jpg';
import funcional1_1024 from '../images/funcional_1_1024.jpg';
import funcional1_1024_2x from '../images/funcional_1_1024_2x.jpg';
import funcional1_470 from '../images/funcional_1_470.jpg';
import funcional1_470_2x from '../images/funcional_1_470_2x.jpg';
import funcional1_380 from '../images/funcional_1_380.jpg';
import funcional1_380_2x from '../images/funcional_1_380_2x.jpg';

import funcional2_1920 from '../images/funcional_2_1920.jpg';
import funcional2_1920_2x from '../images/funcional_2_1920_2x.jpg';
import funcional2_1680 from '../images/funcional_2_1680.jpg';
import funcional2_1680_2x from '../images/funcional_2_1680_2x.jpg';
import funcional2_1440 from '../images/funcional_2_1440.jpg';
import funcional2_1440_2x from '../images/funcional_2_1440_2x.jpg';
import funcional2_1280 from '../images/funcional_2_1280.jpg';
import funcional2_1280_2x from '../images/funcional_2_1280_2x.jpg';
import funcional2_1024 from '../images/funcional_2_1024.jpg';
import funcional2_1024_2x from '../images/funcional_2_1024_2x.jpg';
import funcional2_470 from '../images/funcional_2_470.jpg';
import funcional2_470_2x from '../images/funcional_2_470_2x.jpg';
import funcional2_380 from '../images/funcional_2_380.jpg';
import funcional2_380_2x from '../images/funcional_2_380_2x.jpg';

import fydBike1_1920 from '../images/fyd_bike_1_1920.jpg';
import fydBike1_1920_2x from '../images/fyd_bike_1_1920_2x.jpg';
import fydBike1_1680 from '../images/fyd_bike_1_1680.jpg';
import fydBike1_1680_2x from '../images/fyd_bike_1_1680_2x.jpg';
import fydBike1_1440 from '../images/fyd_bike_1_1440.jpg';
import fydBike1_1440_2x from '../images/fyd_bike_1_1440_2x.jpg';
import fydBike1_1280 from '../images/fyd_bike_1_1280.jpg';
import fydBike1_1280_2x from '../images/fyd_bike_1_1280_2x.jpg';
import fydBike1_1024 from '../images/fyd_bike_1_1024.jpg';
import fydBike1_1024_2x from '../images/fyd_bike_1_1024_2x.jpg';
import fydBike1_470 from '../images/fyd_bike_1_470.jpg';
import fydBike1_470_2x from '../images/fyd_bike_1_470_2x.jpg';
import fydBike1_380 from '../images/fyd_bike_1_380.jpg';
import fydBike1_380_2x from '../images/fyd_bike_1_380_2x.jpg';

import fydBike2_1920 from '../images/fyd_bike_2_1920.jpg';
import fydBike2_1920_2x from '../images/fyd_bike_2_1920_2x.jpg';
import fydBike2_1680 from '../images/fyd_bike_2_1680.jpg';
import fydBike2_1680_2x from '../images/fyd_bike_2_1680_2x.jpg';
import fydBike2_1440 from '../images/fyd_bike_2_1440.jpg';
import fydBike2_1440_2x from '../images/fyd_bike_2_1440_2x.jpg';
import fydBike2_1280 from '../images/fyd_bike_2_1280.jpg';
import fydBike2_1280_2x from '../images/fyd_bike_2_1280_2x.jpg';
import fydBike2_1024 from '../images/fyd_bike_2_1024.jpg';
import fydBike2_1024_2x from '../images/fyd_bike_2_1024_2x.jpg';
import fydBike2_470 from '../images/fyd_bike_2_470.jpg';
import fydBike2_470_2x from '../images/fyd_bike_2_470_2x.jpg';
import fydBike2_380 from '../images/fyd_bike_2_380.jpg';
import fydBike2_380_2x from '../images/fyd_bike_2_380_2x.jpg';

import fydCardio1_1920 from '../images/fyd_cardio_1_1920.jpg';
import fydCardio1_1920_2x from '../images/fyd_cardio_1_1920_2x.jpg';
import fydCardio1_1680 from '../images/fyd_cardio_1_1680.jpg';
import fydCardio1_1680_2x from '../images/fyd_cardio_1_1680_2x.jpg';
import fydCardio1_1440 from '../images/fyd_cardio_1_1440.jpg';
import fydCardio1_1440_2x from '../images/fyd_cardio_1_1440_2x.jpg';
import fydCardio1_1280 from '../images/fyd_cardio_1_1280.jpg';
import fydCardio1_1280_2x from '../images/fyd_cardio_1_1280_2x.jpg';
import fydCardio1_1024 from '../images/fyd_cardio_1_1024.jpg';
import fydCardio1_1024_2x from '../images/fyd_cardio_1_1024_2x.jpg';
import fydCardio1_470 from '../images/fyd_cardio_1_470.jpg';
import fydCardio1_470_2x from '../images/fyd_cardio_1_470_2x.jpg';
import fydCardio1_380 from '../images/fyd_cardio_1_380.jpg';
import fydCardio1_380_2x from '../images/fyd_cardio_1_380_2x.jpg';

import fydCardio2_1920 from '../images/fyd_cardio_2_1920.jpg';
import fydCardio2_1920_2x from '../images/fyd_cardio_2_1920_2x.jpg';
import fydCardio2_1680 from '../images/fyd_cardio_2_1680.jpg';
import fydCardio2_1680_2x from '../images/fyd_cardio_2_1680_2x.jpg';
import fydCardio2_1440 from '../images/fyd_cardio_2_1440.jpg';
import fydCardio2_1440_2x from '../images/fyd_cardio_2_1440_2x.jpg';
import fydCardio2_1280 from '../images/fyd_cardio_2_1280.jpg';
import fydCardio2_1280_2x from '../images/fyd_cardio_2_1280_2x.jpg';
import fydCardio2_1024 from '../images/fyd_cardio_2_1024.jpg';
import fydCardio2_1024_2x from '../images/fyd_cardio_2_1024_2x.jpg';
import fydCardio2_470 from '../images/fyd_cardio_2_470.jpg';
import fydCardio2_470_2x from '../images/fyd_cardio_2_470_2x.jpg';
import fydCardio2_380 from '../images/fyd_cardio_2_380.jpg';
import fydCardio2_380_2x from '../images/fyd_cardio_2_380_2x.jpg';

import pilates1_1920 from '../images/pilates_1_1920.jpg';
import pilates1_1920_2x from '../images/pilates_1_1920_2x.jpg';
import pilates1_1680 from '../images/pilates_1_1680.jpg';
import pilates1_1680_2x from '../images/pilates_1_1680_2x.jpg';
import pilates1_1440 from '../images/pilates_1_1440.jpg';
import pilates1_1440_2x from '../images/pilates_1_1440_2x.jpg';
import pilates1_1280 from '../images/pilates_1_1280.jpg';
import pilates1_1280_2x from '../images/pilates_1_1280_2x.jpg';
import pilates1_1024 from '../images/pilates_1_1024.jpg';
import pilates1_1024_2x from '../images/pilates_1_1024_2x.jpg';
import pilates1_470 from '../images/pilates_1_470.jpg';
import pilates1_470_2x from '../images/pilates_1_470_2x.jpg';
import pilates1_380 from '../images/pilates_1_380.jpg';
import pilates1_380_2x from '../images/pilates_1_380_2x.jpg';

import pilates2_1920 from '../images/pilates_2_1920.jpg';
import pilates2_1920_2x from '../images/pilates_2_1920_2x.jpg';
import pilates2_1680 from '../images/pilates_2_1680.jpg';
import pilates2_1680_2x from '../images/pilates_2_1680_2x.jpg';
import pilates2_1440 from '../images/pilates_2_1440.jpg';
import pilates2_1440_2x from '../images/pilates_2_1440_2x.jpg';
import pilates2_1280 from '../images/pilates_2_1280.jpg';
import pilates2_1280_2x from '../images/pilates_2_1280_2x.jpg';
import pilates2_1024 from '../images/pilates_2_1024.jpg';
import pilates2_1024_2x from '../images/pilates_2_1024_2x.jpg';
import pilates2_470 from '../images/pilates_2_470.jpg';
import pilates2_470_2x from '../images/pilates_2_470_2x.jpg';
import pilates2_380 from '../images/pilates_2_380.jpg';
import pilates2_380_2x from '../images/pilates_2_380_2x.jpg';

import gym1_1920 from '../images/fyd_gym_1_1920.jpg';
import gym1_1920_2x from '../images/fyd_gym_1_1920_2x.jpg';
import gym1_1680 from '../images/fyd_gym_1_1680.jpg';
import gym1_1680_2x from '../images/fyd_gym_1_1680_2x.jpg';
import gym1_1440 from '../images/fyd_gym_1_1440.jpg';
import gym1_1440_2x from '../images/fyd_gym_1_1440_2x.jpg';
import gym1_1280 from '../images/fyd_gym_1_1280.jpg';
import gym1_1280_2x from '../images/fyd_gym_1_1280_2x.jpg';
import gym1_1024 from '../images/fyd_gym_1_1024.jpg';
import gym1_1024_2x from '../images/fyd_gym_1_1024_2x.jpg';
import gym1_470 from '../images/fyd_gym_1_470.jpg';
import gym1_470_2x from '../images/fyd_gym_1_470_2x.jpg';
import gym1_380 from '../images/fyd_gym_1_380.jpg';
import gym1_380_2x from '../images/fyd_gym_1_380_2x.jpg';

import gym2_1920 from '../images/fyd_gym_2_1920.jpg';
import gym2_1920_2x from '../images/fyd_gym_2_1920_2x.jpg';
import gym2_1680 from '../images/fyd_gym_2_1680.jpg';
import gym2_1680_2x from '../images/fyd_gym_2_1680_2x.jpg';
import gym2_1440 from '../images/fyd_gym_2_1440.jpg';
import gym2_1440_2x from '../images/fyd_gym_2_1440_2x.jpg';
import gym2_1280 from '../images/fyd_gym_2_1280.jpg';
import gym2_1280_2x from '../images/fyd_gym_2_1280_2x.jpg';
import gym2_1024 from '../images/fyd_gym_2_1024.jpg';
import gym2_1024_2x from '../images/fyd_gym_2_1024_2x.jpg';
import gym2_470 from '../images/fyd_gym_2_470.jpg';
import gym2_470_2x from '../images/fyd_gym_2_470_2x.jpg';
import gym2_380 from '../images/fyd_gym_2_380.jpg';
import gym2_380_2x from '../images/fyd_gym_2_380_2x.jpg';

import core1_1920 from '../images/core_1_1920.jpg';
import core1_1920_2x from '../images/core_1_1920_2x.jpg';
import core1_1680 from '../images/core_1_1680.jpg';
import core1_1680_2x from '../images/core_1_1680_2x.jpg';
import core1_1440 from '../images/core_1_1440.jpg';
import core1_1440_2x from '../images/core_1_1440_2x.jpg';
import core1_1280 from '../images/core_1_1280.jpg';
import core1_1280_2x from '../images/core_1_1280_2x.jpg';
import core1_1024 from '../images/core_1_1024.jpg';
import core1_1024_2x from '../images/core_1_1024_2x.jpg';
import core1_470 from '../images/core_1_470.jpg';
import core1_470_2x from '../images/core_1_470_2x.jpg';
import core1_380 from '../images/core_1_380.jpg';
import core1_380_2x from '../images/core_1_380_2x.jpg';

import core2_1920 from '../images/core_2_1920.jpg';
import core2_1920_2x from '../images/core_2_1920_2x.jpg';
import core2_1680 from '../images/core_2_1680.jpg';
import core2_1680_2x from '../images/core_2_1680_2x.jpg';
import core2_1440 from '../images/core_2_1440.jpg';
import core2_1440_2x from '../images/core_2_1440_2x.jpg';
import core2_1280 from '../images/core_2_1280.jpg';
import core2_1280_2x from '../images/core_2_1280_2x.jpg';
import core2_1024 from '../images/core_2_1024.jpg';
import core2_1024_2x from '../images/core_2_1024_2x.jpg';
import core2_470 from '../images/core_2_470.jpg';
import core2_470_2x from '../images/core_2_470_2x.jpg';
import core2_380 from '../images/core_2_380.jpg';
import core2_380_2x from '../images/core_2_380_2x.jpg';

import coreIcon from '../images/core_icon.svg';
import cardioIcon from '../images/cardio_icon.svg';
import funcionalIcon from '../images/funcional_icon.svg';
import pilatesIcon from '../images/pilates_icon.svg';
import gymIcon from '../images/gym_icon.svg';
import bikeIcon from '../images/bike_icon.svg';

import backgroundDetail from '../images/background_detail.svg';

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageIndex: 0,
      screenWidth: window.innerWidth,
    };

    this.serviceOptions = [
      'funcional',
      'pilates',
      'gym',
      'bike',
      'core',
      'cardio',
    ];
    this.imageCountMap = {
      'funcional': 2,
      'pilates': 2,
      'gym': 2,
      'bike': 2,
      'core': 2,
      'cardio': 2,
    };

    this.runAnimation = true;
  }

  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', 'G-FXDWBKYNPM', {'page_path': this.props.location.pathname});
      // window.gtag('config', 'G-FXDWBKYNPM', {'page_path': routes.MAIN_PATH});
    }

    // let params = new URLSearchParams(window.location.search);
    // const selectedService = params.get('servico');

    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);

    // this.scrollListener = () => this.updateScroll();
    // window.addEventListener("scroll", this.scrollListener);

    this.startTimer();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
    // window.removeEventListener("scroll", this.scrollListener);

    this.runAnimation = false;
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  getSelectedService() {
    if(this.props.match.params.service && this.serviceOptions.includes(this.props.match.params.service)) {
      return this.props.match.params.service;
    }

    return 'funcional';
  }

  getServiceInfo() {
    switch (this.getSelectedService()) {
      case 'funcional':
        return {
          title: (
            <h2 className="main-page__service__title--funcional"><span className="main-page__red-text">FYD</span> Funcional</h2>
          ),
          subTitle: <h3 className="main-page__service__sub-title--funcional">Treinamento funcional</h3>,
          description: 'Um programa de treinamento com o foco em desenvolver o condicionamento físico geral, ' +
                       'proporcionando a mais ampla adaptação fisiológica e oferecendo resultados incríveis treinando apenas 1 hora por dia. ' +
                       'Uma atividade dinâmica voltada para qualquer pessoa, independente de idade ou nível físico, e que aplica movimentos ' +
                       'funcionais inspirados na ginástica, no levantamento de peso e no atletismo.'
        };
      case 'pilates':
        return {
          title: (
            <h2 className="main-page__service__title--pilates"><span className="main-page__red-text">FYD</span> Pilates</h2>
          ),
          subTitle: <h3 className="main-page__service__sub-title--pilates">Com aparelhos</h3>,
          description: 'Um treino de condicionamento postural onde fundimos o Pilates tradicional (com aparelhos e solo), ' +
                       'treinamento funcional e Yoga. Uma atividade para o corpo e mente, onde trabalhamos tanto para melhorar ' +
                       'sua postura, mobilidade articular e força, quanto sua consciência corporal, com exercícios de respiração ' +
                       'que ajudam a diminuir o estresse e ansiedade para proporcional melhor qualidade de vida.'
        };
      case 'gym':
        return {
          title: (
            <h2 className="main-page__service__title--gym"><span className="main-page__red-text">FYD</span> Gym</h2>
          ),
          subTitle: <h3 className="main-page__service__sub-title--gym">Musculação</h3>,
          description: 'Uma forma revigorada da musculação tradicional, a FYD Gym é um treino indicado para pessoas de ' +
                       'qualquer idade ou condicionamento físico que buscam desde o ganho de massa muscular (hipertrofia) ' +
                       'até o emagrecimento ou performance. Com um cronograma personalizado e dinâmico, ' +
                       'trazemos mais eficiência para que você alcance seus objetivos gastando menos tempo na academia e sem ' +
                       'aqueles treinos repetidos e maçantes.'
        };
      case 'bike':
        return {
          title: (
            <h2 className="main-page__service__title--bike"><span className="main-page__red-text">FYD</span> Bike</h2>
          ),
          subTitle: <h3 className="main-page__service__sub-title--bike">Spinning</h3>,
          description: 'Desafiador, divertido, e seguro, o FYD Bike é um treino de ciclismo indoor que irá te ' +
                       'proporcionar ótimos resultados em pouquíssimo tempo. Com a aplicação de tecnologias como o de monitoramento cardíaco, ' +
                       'oferecemos treinos de ciclismo elaborados ajustados de forma personalizada para o seu condicionamento físico. ' +
                       'No pelotão FYD Bike ninguém fica para trás e todos chegam juntos!'
        };
      case 'core':
        return {
          title: (
            <h2 className="main-page__service__title--core"><span className="main-page__red-text">FYD</span> Core</h2>
          ),
          subTitle: <h3 className="main-page__service__sub-title--core">Postura</h3>,
          description: 'Um core mais forte torna você melhor em quase tudo que você faz, da vida cotidiana às atividades ' +
                       'favoritas. Também indicado para todas as idades e condicionamento físico, o FYD Core é constituído de treinos ' +
                       'de curta duração com exercícios que vão fortalecer e definir os músculos das regiões abdominais, ' +
                       'lombar, pélvis e quadril.'
        };
      case 'cardio':
        return {
          title: (
            <h2 className="main-page__service__title--cardio"><span className="main-page__red-text">FYD</span> Cardio</h2>
          ),
          subTitle: <h3 className="main-page__service__sub-title--cardio">Emagrecimento</h3>,
          description: 'Se você procura queimar muitas calorias, emagrecer, melhorar seu condicionamento e resistência física, então o ' +
                       'FYD Cardio é para você! Um treino coletivo dinâmico e divertido de alta intensidade que combina movimentos de esportes, como ' +
                       'corrida e salto, além de exercícios tradicionais de força para atender desde os alunos iniciantes até os mais energéticos.'
        };
      default:
        return {
          title: "Não definido",
          description: 'Não definido'
        };
    }
  }

  checkImageVisible(service, index) {
    return this.getSelectedService() === service && this.state.imageIndex === index;
  }

  onMoveSelectedService(step) {
    let index = this.serviceOptions.indexOf(this.getSelectedService());

    index += step;

    if(index < 0) {
      index = this.serviceOptions.length - 1;
    }
    else if(index > this.serviceOptions.length - 1) {
      index = 0;
    }

    this.resetTimer();

    this.setState({
      selectedService: this.serviceOptions[index],
      imageIndex: 0
    });

    this.props.history.push(`${routes.SERVICES_PATH}${this.serviceOptions[index]}`);
  }

  onSelectService(service) {
    if(this.getSelectedService() === service) {
      return;
    }

    if(process.env.NODE_ENV === 'production') {
      window.gtag('event', 'Click', {
        'event_category' : 'Service link',
        'event_label' : service
      });
    }

    this.resetTimer();

    this.setState({
      selectedService: service,
      imageIndex: 0
    });
  }

  resetTimer() {
    this.timeReference = Date.now();
  }

  startTimer() {
    this.resetTimer();

    requestAnimationFrame(this.updateTimer.bind(this));
  }

  updateTimer() {
    if(!this.runAnimation) {
      return;
    }

    let elapsedTime = ((Date.now() - this.timeReference)/1000);

    if(elapsedTime > 8) {
      const max = this.imageCountMap[this.getSelectedService()] - 1;
      let imageIndex = this.state.imageIndex + 1;

      if(imageIndex > max) {
        imageIndex = 0;
      }

      this.resetTimer();

      this.setState({imageIndex});
    }

    requestAnimationFrame(this.updateTimer.bind(this));
  }

  onSelectImage(imageIndex) {
    if(this.state.imageIndex === imageIndex) {
      return;
    }

    this.resetTimer();

    this.setState({
      imageIndex: imageIndex
    });
  }

  getImageSelectors() {
    let output = [];

    for(let i=0; i < this.imageCountMap[this.getSelectedService()]; ++i) {
      output.push(
        <li
          key={`image_selector:${i}`}
          className="main-page__service-image__image-selector__option"
        >

          <button
            className={`main-page__service-image__image-selector__button${i === this.state.imageIndex ? '--selected' : ''}`}
            onClick={() => this.onSelectImage(i)}
          >

            <div className="main-page__service-image__image-selector__button__icon"></div>

          </button>

        </li>
      );
    }

    return output;
  }

  render() {
    const serviceInfo = this.getServiceInfo();

    return (
      <React.Fragment>

        <MainHeader
          location={this.props.location}
        />

        <div className="main-page__wrapper">

          <main className="main-page">

            <div className="main-page__main-wrapper">

              <section className="main-page__service">

                {serviceInfo.title}

                {serviceInfo.subTitle}

                <p className="main-page__service__description">{serviceInfo.description}</p>

                <Link
                  className="main-page__service__button"
                  to={`${routes.SUBSCRIBE_PATH}?servico=${this.getSelectedService()}`}
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Click', {
                        'event_category' : 'Service main link',
                        'event_label' : 'Saiba mais'
                      });
                    }
                  }}
                >

                    Saiba mais

                </Link>

              </section>

              <aside className="main-page__service-selector">

                <div className="main-page__service-selector__wrapper">

                  <button
                    className="main-page__service-selector__selector"
                    onClick={() => this.onMoveSelectedService(-1)}
                  >

                    <i className="fas fa-chevron-left"></i>

                  </button>

                  <ul className="main-page__service-selector__list">

                    <li className="main-page__service-selector__item">
                      <Link
                        className={`main-page__service-selector__item__link${this.getSelectedService() === 'funcional' ? '--selected' : ''}`}
                        to={`${routes.SERVICES_PATH}funcional`}
                        onClick={() => this.onSelectService('funcional')}
                      >

                        <img
                          className="main-page__service-selector__item__image--funcional"
                          src={funcionalIcon}
                          alt="Ícone representando a atividade de treinamento funcional"
                        />

                      </Link>
                    </li>

                    <li className="main-page__service-selector__item">
                      <Link
                        className={`main-page__service-selector__item__link${this.getSelectedService() === 'pilates' ? '--selected' : ''}`}
                        to={`${routes.SERVICES_PATH}pilates`}
                        onClick={() => this.onSelectService('pilates')}
                      >

                        <img
                          className="main-page__service-selector__item__image--pilates"
                          src={pilatesIcon}
                          alt="Ícone representando a atividade de pilates"
                        />

                      </Link>
                    </li>

                    <li className="main-page__service-selector__item">
                      <Link
                        className={`main-page__service-selector__item__link${this.getSelectedService() === 'gym' ? '--selected' : ''}`}
                        to={`${routes.SERVICES_PATH}gym`}
                        onClick={() => this.onSelectService('gym')}
                      >

                        <img
                          className="main-page__service-selector__item__image--gym"
                          src={gymIcon}
                          alt="Ícone representando a atividade de musculação"
                        />

                      </Link>
                    </li>

                    <li className="main-page__service-selector__item">
                      <Link
                        className={`main-page__service-selector__item__link${this.getSelectedService() === 'bike' ? '--selected' : ''}`}
                        to={`${routes.SERVICES_PATH}bike`}
                        onClick={() => this.onSelectService('bike')}
                      >

                        <img
                          className="main-page__service-selector__item__image--bike"
                          src={bikeIcon}
                          alt="Ícone representando a atividade de bicicleta"
                        />

                      </Link>
                    </li>

                    <li className="main-page__service-selector__item">
                      <Link
                        className={`main-page__service-selector__item__link${this.getSelectedService() === 'core' ? '--selected' : ''}`}
                        to={`${routes.SERVICES_PATH}core`}
                        onClick={() => this.onSelectService('core')}
                      >

                        <img
                          className="main-page__service-selector__item__image--core"
                          src={coreIcon}
                          alt="Ícone representando a atividade de core"
                        />

                      </Link>
                    </li>

                    <li className="main-page__service-selector__item">
                      <Link
                        className={`main-page__service-selector__item__link${this.getSelectedService() === 'cardio' ? '--selected' : ''}`}
                        to={`${routes.SERVICES_PATH}cardio`}
                        onClick={() => this.onSelectService('cardio')}
                      >

                        <img
                          className="main-page__service-selector__item__image--cardio"
                          src={cardioIcon}
                          alt="Ícone representando a atividade de cardio"
                        />

                      </Link>
                    </li>

                  </ul>

                  <button
                    className="main-page__service-selector__selector"
                    onClick={() => this.onMoveSelectedService(1)}
                  >

                    <i className="fas fa-chevron-right"></i>

                  </button>

                </div>

              </aside>

            </div>

            <section className="main-page__service-image">

              <div className="main-page__service-image__side-image-wrapper">

                <img
                  className="main-page__service-image__side-image"
                  src={backgroundDetail}
                  alt=""
                />

              </div>

              <div className="main-page__service-image__image-wrapper">

                <div className="main-page__service-image__background"></div>

                <img
                  key="service:funcional:image:0"
                  className={`main-page__service-image__image${this.checkImageVisible('funcional', 0) ? '--left' : '--hidden'}`}
                  style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "20% center",
                  }}
                  src={funcional1_1920}
                  srcSet={`${funcional1_1920} 1920w,
                           ${funcional1_1920_2x} 3840w,
                           ${funcional1_1680} 1680w,
                           ${funcional1_1680_2x} 3360w,
                           ${funcional1_1440} 1440w,
                           ${funcional1_1440_2x} 2880w,
                           ${funcional1_1280} 1280w,
                           ${funcional1_1280_2x} 2560w,
                           ${funcional1_1024} 1024w,
                           ${funcional1_1024_2x} 2048w,
                           ${funcional1_470} 470w,
                           ${funcional1_470_2x} 940w,
                           ${funcional1_380} 380w,
                           ${funcional1_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Grupo realizando agachamentos"
                />
                <img
                  key="service:funcional:image:1"
                  className={`main-page__service-image__image${this.checkImageVisible('funcional', 1) ? '--left' : '--hidden'}`}
                  style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "20% center",
                  }}
                  src={funcional2_1920}
                  srcSet={`${funcional2_1920} 1920w,
                           ${funcional2_1920_2x} 3840w,
                           ${funcional2_1680} 1680w,
                           ${funcional2_1680_2x} 3360w,
                           ${funcional2_1440} 1440w,
                           ${funcional2_1440_2x} 2880w,
                           ${funcional2_1280} 1280w,
                           ${funcional2_1280_2x} 2560w,
                           ${funcional2_1024} 1024w,
                           ${funcional2_1024_2x} 2048w,
                           ${funcional2_470} 470w,
                           ${funcional2_470_2x} 940w,
                           ${funcional2_380} 380w,
                           ${funcional2_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Coach monitorando aluno realizando flexões"
                />
                <img
                  key="service:pilate:image:0"
                  className={`main-page__service-image__image${this.checkImageVisible('pilates', 0) ? '--right' : '--hidden'}`}
                  style={{
                    transformOrigin: "75% 25%",
                    objectPosition: "90% center",
                  }}
                  src={pilates1_1920}
                  srcSet={`${pilates1_1920} 1920w,
                           ${pilates1_1920_2x} 3840w,
                           ${pilates1_1680} 1680w,
                           ${pilates1_1680_2x} 3360w,
                           ${pilates1_1440} 1440w,
                           ${pilates1_1440_2x} 2880w,
                           ${pilates1_1280} 1280w,
                           ${pilates1_1280_2x} 2560w,
                           ${pilates1_1024} 1024w,
                           ${pilates1_1024_2x} 2048w,
                           ${pilates1_470} 470w,
                           ${pilates1_470_2x} 940w,
                           ${pilates1_380} 380w,
                           ${pilates1_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Mulher realizando exercício em reformer em pé"
                />
                <img
                  key="service:pilate:image:1"
                  className={`main-page__service-image__image${this.checkImageVisible('pilates', 1) ? '--center' : '--hidden'}`}
                  style={{
                    transformOrigin: "50% 25%",
                    objectPosition: "center"
                  }}
                  src={pilates2_1920}
                  srcSet={`${pilates2_1920} 1920w,
                           ${pilates2_1920_2x} 3840w,
                           ${pilates2_1680} 1680w,
                           ${pilates2_1680_2x} 3360w,
                           ${pilates2_1440} 1440w,
                           ${pilates2_1440_2x} 2880w,
                           ${pilates2_1280} 1280w,
                           ${pilates2_1280_2x} 2560w,
                           ${pilates2_1024} 1024w,
                           ${pilates2_1024_2x} 2048w,
                           ${pilates2_470} 470w,
                           ${pilates2_470_2x} 940w,
                           ${pilates2_380} 380w,
                           ${pilates2_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Mulher realizando exercício em reformer sentada"
                />
                <img
                  key="service:gym:image:0"
                  className={`main-page__service-image__image${this.checkImageVisible('gym', 0) ? '--left' : '--hidden'}`}
                  style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "20% center",
                  }}
                  src={gym1_1920}
                  srcSet={`${gym1_1920} 1920w,
                           ${gym1_1920_2x} 3840w,
                           ${gym1_1680} 1680w,
                           ${gym1_1680_2x} 3360w,
                           ${gym1_1440} 1440w,
                           ${gym1_1440_2x} 2880w,
                           ${gym1_1280} 1280w,
                           ${gym1_1280_2x} 2560w,
                           ${gym1_1024} 1024w,
                           ${gym1_1024_2x} 2048w,
                           ${gym1_470} 470w,
                           ${gym1_470_2x} 940w,
                           ${gym1_380} 380w,
                           ${gym1_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Coach instruindo aluno para exercutar exercício com halteres"
                />
                <img
                  key="service:gym:image:1"
                  className={`main-page__service-image__image${this.checkImageVisible('gym', 1) ? '--left' : '--hidden'}`}
                  style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "20% center",
                  }}
                  src={gym2_1920}
                  srcSet={`${gym2_1920} 1920w,
                           ${gym2_1920_2x} 3840w,
                           ${gym2_1680} 1680w,
                           ${gym2_1680_2x} 3360w,
                           ${gym2_1440} 1440w,
                           ${gym2_1440_2x} 2880w,
                           ${gym2_1280} 1280w,
                           ${gym2_1280_2x} 2560w,
                           ${gym2_1024} 1024w,
                           ${gym2_1024_2x} 2048w,
                           ${gym2_470} 470w,
                           ${gym2_470_2x} 940w,
                           ${gym2_380} 380w,
                           ${gym2_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Coach monitorando aluna durante exercício"
                />
                <img
                  key="service:bike:image:0"
                  className={`main-page__service-image__image${this.checkImageVisible('bike', 0) ? '--center' : '--hidden'}`}
                  style={{
                    transformOrigin: "50% 25%",
                    objectPosition: "center"
                  }}
                  src={fydBike1_1920}
                  srcSet={`${fydBike1_1920} 1920w,
                           ${fydBike1_1920_2x} 3840w,
                           ${fydBike1_1680} 1680w,
                           ${fydBike1_1680_2x} 3360w,
                           ${fydBike1_1440} 1440w,
                           ${fydBike1_1440_2x} 2880w,
                           ${fydBike1_1280} 1280w,
                           ${fydBike1_1280_2x} 2560w,
                           ${fydBike1_1024} 1024w,
                           ${fydBike1_1024_2x} 2048w,
                           ${fydBike1_470} 470w,
                           ${fydBike1_470_2x} 940w,
                           ${fydBike1_380} 380w,
                           ${fydBike1_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Alunos se exercitando na bicicleta"
                />
                <img
                  key="service:bike:image:1"
                  className={`main-page__service-image__image${this.checkImageVisible('bike', 1) ? '--right' : '--hidden'}`}
                  style={{
                    transformOrigin: "75% 25%",
                    objectPosition: "90% center",
                  }}
                  src={fydBike2_1920}
                  srcSet={`${fydBike2_1920} 1920w,
                           ${fydBike2_1920_2x} 3840w,
                           ${fydBike2_1680} 1680w,
                           ${fydBike2_1680_2x} 3360w,
                           ${fydBike2_1440} 1440w,
                           ${fydBike2_1440_2x} 2880w,
                           ${fydBike2_1280} 1280w,
                           ${fydBike2_1280_2x} 2560w,
                           ${fydBike2_1024} 1024w,
                           ${fydBike2_1024_2x} 2048w,
                           ${fydBike2_470} 470w,
                           ${fydBike2_470_2x} 940w,
                           ${fydBike2_380} 380w,
                           ${fydBike2_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Homem checando seu batimento cardíaco durante exercício de bicicleta"
                />
                <img
                  key="service:core:image:0"
                  className={`main-page__service-image__image${this.checkImageVisible('core', 0) ? '--right' : '--hidden'}`}
                  style={{
                    transformOrigin: "75% 25%",
                    objectPosition: "90% center",
                  }}
                  src={core1_1920}
                  srcSet={`${core1_1920} 1920w,
                           ${core1_1920_2x} 3840w,
                           ${core1_1680} 1680w,
                           ${core1_1680_2x} 3360w,
                           ${core1_1440} 1440w,
                           ${core1_1440_2x} 2880w,
                           ${core1_1280} 1280w,
                           ${core1_1280_2x} 2560w,
                           ${core1_1024} 1024w,
                           ${core1_1024_2x} 2048w,
                           ${core1_470} 470w,
                           ${core1_470_2x} 940w,
                           ${core1_380} 380w,
                           ${core1_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Grupo realizando exercício de prancha"
                />
                <img
                  key="service:core:image:1"
                  className={`main-page__service-image__image${this.checkImageVisible('core', 1) ? '--left' : '--hidden'}`}
                  style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "20% center",
                  }}
                  src={core1_1920}
                  srcSet={`${core2_1920} 1920w,
                           ${core2_1920_2x} 3840w,
                           ${core2_1680} 1680w,
                           ${core2_1680_2x} 3360w,
                           ${core2_1440} 1440w,
                           ${core2_1440_2x} 2880w,
                           ${core2_1280} 1280w,
                           ${core2_1280_2x} 2560w,
                           ${core2_1024} 1024w,
                           ${core2_1024_2x} 2048w,
                           ${core2_470} 470w,
                           ${core2_470_2x} 940w,
                           ${core2_380} 380w,
                           ${core2_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Grupo executando exercícios de postura"
                />
                <img
                  key="service:cardio:image:0"
                  className={`main-page__service-image__image${this.checkImageVisible('cardio', 0) ? '--left' : '--hidden'}`}
                  style={{
                    transformOrigin: "25% 25%",
                    objectPosition: "20% center",
                  }}
                  src={fydCardio1_1920}
                  srcSet={`${fydCardio1_1920} 1920w,
                           ${fydCardio1_1920_2x} 3840w,
                           ${fydCardio1_1680} 1680w,
                           ${fydCardio1_1680_2x} 3360w,
                           ${fydCardio1_1440} 1440w,
                           ${fydCardio1_1440_2x} 2880w,
                           ${fydCardio1_1280} 1280w,
                           ${fydCardio1_1280_2x} 2560w,
                           ${fydCardio1_1024} 1024w,
                           ${fydCardio1_1024_2x} 2048w,
                           ${fydCardio1_470} 470w,
                           ${fydCardio1_470_2x} 940w,
                           ${fydCardio1_380} 380w,
                           ${fydCardio1_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Grupo de pessoas de pessoas se exercitando"
                />
                <img
                  key="service:cardio:image:1"
                  className={`main-page__service-image__image${this.checkImageVisible('cardio', 1) ? '--right' : '--hidden'}`}
                  style={{
                    transformOrigin: "75% 25%",
                    objectPosition: "90% center",
                  }}
                  src={fydCardio2_1920}
                  srcSet={`${fydCardio2_1920} 1920w,
                           ${fydCardio2_1920_2x} 3840w,
                           ${fydCardio2_1680} 1680w,
                           ${fydCardio2_1680_2x} 3360w,
                           ${fydCardio2_1440} 1440w,
                           ${fydCardio2_1440_2x} 2880w,
                           ${fydCardio2_1280} 1280w,
                           ${fydCardio2_1280_2x} 2560w,
                           ${fydCardio2_1024} 1024w,
                           ${fydCardio2_1024_2x} 2048w,
                           ${fydCardio2_470} 470w,
                           ${fydCardio2_470_2x} 940w,
                           ${fydCardio2_380} 380w,
                           ${fydCardio2_380_2x} 760w`}
                  sizes="(max-width: 380px) 380px,(max-width: 470px) 470px,(max-width: 580px) 1024px,(max-width: 760px) 1280px, (max-width: 870px) 1440px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px, 1920px"
                  alt="Dupla de mulheres prontas para se exercitarem"
                />

                <div className="main-page__service-image__overlay-filter"></div>

                <aside className="main-page__service-image__image-selector-wrapper">

                  <ul className="main-page__service-image__image-selector">

                    {this.getImageSelectors()}

                  </ul>

                </aside>

              </div>

            </section>

          </main>

          <MainFooter />

        </div>

      </React.Fragment>
    );
  }
}

export default MainPage
