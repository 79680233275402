const DEFAULT_ERROR_MESSAGE = `Infelizmente estamos com problemas em nosso serviço. ` +
                              `Certifique-se de que esteja conectado na internet e, ` +
                              `por favor, tente novamente mais tarde.`;

const STATE_API = '/request_state';

const PRESALE_LEAD_POST_API = '/presale-subscription';

export {
  DEFAULT_ERROR_MESSAGE,
  STATE_API,
  PRESALE_LEAD_POST_API
};


const MAIN_PATH = '/';
const SERVICES_PATH = '/servicos/';
const OFFERS_PATH = '/ofertas';
const SUBSCRIBE_PATH = '/tenho-interesse';

export {
  MAIN_PATH,
  SERVICES_PATH,
  OFFERS_PATH,
  SUBSCRIBE_PATH
};
